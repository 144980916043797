import React, { useEffect } from 'react';
import i18n from 'i18next';
import useLanguage from 'lib/hooks/useLanguage';

const I18nInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const lng = useLanguage();

  useEffect(() => {
    if (lng?.value) {
      i18n.changeLanguage(lng.value);
    } else {
      i18n.changeLanguage('en'); // Default language
    }
  }, [lng]);

  if (!lng) {
    return (
      <span className="sr-only">Loading...</span>
    );
  }

  return <>{children}</>;
};

export default I18nInitializer;
