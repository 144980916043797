import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export enum EmailTemplateType {
  TEMPLATE_TYPE_STARTED = "1",
  TEMPLATE_TYPE_PAYMENT_FAILED = "2",
  TEMPLATE_TYPE_REGISTERD_AND_AWAITING_PAYMENT = "3",
  TEMPLATE_TYPE_AWATING_PAY_COMPLETE = "4",
  TEMPLATE_TYPE_REGISTERD_AND_PAYMENT = "5",
  TEMPLATE_TYPE_PAY_RECEIPT = "6",
  TEMPLATE_TYPE_AWATING_PICKUP = "7",
  TEMPLATE_TYPE_COORDINATE_DELIVERY = "8",
  TEMPLATE_TYPE_SHIPPED = "9",
  TEMPLATE_TYPE_SATISFACTION_POLL = "10",
}

export interface iEmailTemplate extends iBaseEntity {
  // id: string;
  type: EmailTemplateType;
  emailTemplate: string;
  enabled: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  // name: string;
  subject: string;
  // body: string;
}

export interface iEmailTemplateModel {
  id?: number;
  type: number;
  email_template: string;
  enabled: number;
  created_at?: string;
  updated_at?: string;
  subject: string;
}

export default class EmailTemplate extends EntityCRUD<iEmailTemplate> {
  static basePath = "/email-templates";

  static adapter(data: iEmailTemplate | iEmailTemplateModel) {
    if ((data as iEmailTemplateModel).email_template !== undefined) {
      const model = data as iEmailTemplateModel;
      return {
        id: model.id?.toString(),
        type: model.type,
        enabled: !!model.enabled,
        subject: model.subject,
        emailTemplate: model.email_template,
        createdAt: model.created_at && new Date(model.created_at),
        updatedAt: model.updated_at && new Date(model.updated_at),
      };
    } else {
      const item = data as iEmailTemplate;
      return {
        id: item.id,
        type: item.type,
        enabled: +item.enabled,
        subject: item.subject,
        email_template: item.emailTemplate,
        created_at: item.createdAt && item.createdAt.toISOString(),
        updated_at: item.updatedAt && item.updatedAt.toISOString(),
      };
    }
  }

  static create(data: iEmailTemplate) {
    return EntityCRUD.createBase<iEmailTemplate>(data);
  }
}
