import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

interface InvoiceMetadata {
  ivaPercentage?: number;
  [key: string]: any;
}

export interface iIntegrations<T = any> extends iBaseEntity {
  id: string;
  name: string;
  service: string;
  serviceUrl: string;
  apiKey: string;
  currency: string;
  metadata: T;
  createdAt: string;
  updatedAt: string;
}

export interface iIntegrationsModel {
  id: string;
  name: string;
  service: string;
  service_url: string;
  api_key: string;
  currency: string;
  metadata?: string | object;
  created_at: string;
  updated_at: string;
}

export default class Integrations extends EntityCRUD<iIntegrations> {
  static basePath = "/integrations";

  static adapter(data: iIntegrationsModel | iIntegrations) {
    if ((data as iIntegrationsModel).api_key) {
      const model = data as iIntegrationsModel;
      const metadata = model?.metadata || {};
      return {
        id: model.id?.toString(),
        name: model?.name,
        service: model?.service,
        serviceUrl: model?.service_url,
        apiKey: model?.api_key,
        currency: model?.currency,
        createdAt: model?.created_at,
        updatedAt: model?.updated_at,
        metadata: typeof metadata === 'string' ? JSON.parse(metadata) : metadata,
      };
    } else {
      const model = data as iIntegrations;
      const metadata = model?.metadata || {};
      return {
        id: model.id?.toString(),
        name: model?.name,
        service: model?.service,
        service_url: model?.serviceUrl,
        api_key: model?.apiKey,
        currency: model?.currency,
        metadata: typeof metadata === 'string' ? JSON.parse(metadata) : metadata,
      };
    }
  }

  static create(data: iIntegrations) {
    return EntityCRUD.createBase<iIntegrations>(data);
  }

  getInvoiceIntegration() {
    const path = `${Integrations.basePath}/invoice`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path);
          const invoiceIntegration = Integrations.adapter(response.data);
          return invoiceIntegration as iIntegrations<InvoiceMetadata>;
        } catch (error) {
          // Handle error
          throw error;
        }
      },
    };
  }
}