import { storeAuth } from "lib/auth";
import fetch, { iEnmarcoWrapper } from "../fetch";
import { iUsableFetch } from "./EntityCRUD";
import Image from "./Image";

import Order from "./Order";
import State from "./State";
import PickupPlace from "./PickupPlace";
import DeliveryDayTiming from "./DeliveryDayTiming";
import DeliveryOffDay from "./DeliveryOffDay";
import Transporter from "./Transporter";
import User from "./User";
import EmailTemplate from "./EmailTemplate";
import City from "./City";
import Product from "./Products";
import Notification from "./Notifications";
import SiteImage from "./SiteImage";
import PaperType from "./PaperType";
import Integrations from "./Integrations";
import ZureoInvoicing from "./ZureoInvoicing";
import SuscriptionPlan from "./SuscriptionPlan";
import Config from "./Config";

export interface iAuthData {
  email: string;
  password: string;
}

export interface iAdminUser {
  name: string;
  email: string;
  updated_at: string;
  access_token?: string;
}
class Api {
  public fetch<Data = any>(
    path: string,
    data?: object,
    options: object = {}
  ): iUsableFetch<Data> {
    return {
      key: path,
      fetcher() {
        return fetch<Data>(path, data, options);
      },
    };
  }

  auth(authData: iAuthData) {
    return fetch<iEnmarcoWrapper<iAdminUser>>("/login", authData).then(
      (response) => {
        if (response.data.access_token) storeAuth(response.data.access_token);
        return response.data;
      }
    );
  }

  me() {
    return fetch<iEnmarcoWrapper<iAdminUser>>("/me");
  }

  user(id: string = "") {
    return new User(id);
  }

  image(id: string = "") {
    return new Image(id);
  }

  order(id: string = "") {
    return new Order(id);
  }

  pickupPlace(id: string = "") {
    return new PickupPlace(id);
  }

  state(id: string = "") {
    return new State(id);
  }

  transporter(id: string = "") {
    return new Transporter(id);
  }

  city(id: string = "") {
    return new City(id);
  }

  deliveryDayTiming(id: string = "") {
    return new DeliveryDayTiming(id);
  }

  deliveryOffDay(id: string = "") {
    return new DeliveryOffDay(id);
  }

  emailTemplates(id: string = "") {
    return new EmailTemplate(id);
  }

  product() {
    return new Product();
  }

  notification(id: string = "") {
    return new Notification(id);
  }

  siteImage(id: string = "") {
    return new SiteImage(id);
  }

  paperType(id: string = "") {
    return new PaperType(id);
  }

  integrations(id: string = "") {
    return new Integrations(id);
  }

  zureoInvoicing() {
    return new ZureoInvoicing();
  }

  config(id: string = "") {
    return new Config(id);
  }

  suscriptionPlan(id: string = "") {
    return new SuscriptionPlan(id);
  }
}

export const api = new Api();
