import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iOrderIntegrations extends iBaseEntity {
    id: string;
    orderId: string;
    serviceName: string;
    externalId?: string;
}

export type iOrderIntegrationsModel = Omit<iOrderIntegrations, "orderId" | "serviceName" | "externalId"> & {
    order_id: string;
    service_name: string;
    external_id: string;
};

export enum IntegrationType {
    Zureo = "ZUREO",
}

export default class OrderIntegrations extends EntityCRUD<iOrderIntegrations> {
    static basePath = "/order-integrations";

    static arrayAdapter(data: iOrderIntegrations[] | iOrderIntegrationsModel[]) {
        if (data.length && (data as iOrderIntegrationsModel[])[0].order_id !== undefined) {
            const models = data as iOrderIntegrationsModel[];
            return models.map((model) => ({
                id: model.id,
                orderId: model.order_id,
                serviceName: model.service_name,
                externalId: model.external_id,
            }));
        } else {
            return data as iOrderIntegrations[];
        }
    }

    static create(data: iOrderIntegrations) {
        return EntityCRUD.createBase<iOrderIntegrations>(data);
    }
}
