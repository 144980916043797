import axios, { AxiosRequestConfig } from "axios";
import { getAuth } from "./auth";

const { REACT_APP_API_BASE_URL, REACT_APP_MOCKAPI_BASE_URL } = process.env;

export interface iEnmarcoWrapper<Data = any> {
  success: boolean;
  data: Data;
}

export default async function fetch<Data = any>(
  path: string,
  data?: object,
  options: AxiosRequestConfig = {}
): Promise<Data> {
  const baseUrl = !path.startsWith("~")
    ? REACT_APP_API_BASE_URL
    : REACT_APP_MOCKAPI_BASE_URL;

  const r = await axios({
    method: data === undefined ? "get" : "post",
    ...options,
    headers: {
      Authorization: `Bearer ${getAuth()}`,
      ...options?.headers,
    },
    // remove ~ for fake api
    url: `${baseUrl}${path.replace(/^~/, "")}`,
    data,
  });
  return r.data;
}
