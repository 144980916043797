import fetch from "lib/fetch";

export interface iCreateZureoInvoice {
    orderId: string;
    receiptType: string;
    subsidiaryId: string;
    currency: string;
    comments: string;
}

export interface iZureoCurrency {
    id: number;
    nombre: string;
    simbolo: string;
    codigo_internacional: number;
    codigo_ISO4217: string;
    decimales: number;
}

export interface iZureoOrderType {
    Id: number;
    Nombre: string;
    Sucursal: number;
};

export interface iCreateZureoOrderResponse {
    invoicedOrders: {
        [key: string]: {
            success: boolean;
            reason?: string;
        };
    }
}

export default class ZureoInvoicing {
    static basePath = "/integrations/zureo";

    getOrderTypes() {
        const path = `${ZureoInvoicing.basePath}/orderTypes`;
        return {
            key: path,
            fetcher: async () => {
                try {
                    const response = await fetch(path);
                    return response?.data as iZureoOrderType[];
                } catch (error) {
                    // Handle error
                    throw error;
                }
            },
        };
    }

    getTypesOfCurrency() {
        const path = `${ZureoInvoicing.basePath}/currency`;
        return {
            key: path,
            fetcher: async () => {
                try {
                    const response = await fetch(path);
                    return response?.data as iZureoCurrency[];
                } catch (error) {
                    // Handle error
                    throw error;
                }
            },
        };
    }

    createZureoOrder(data: iCreateZureoInvoice[]) {
        const path = `${ZureoInvoicing.basePath}/createOrder`;
        return {
            key: path,
            fetcher: async () => {
                try {
                    const response = await fetch(path, data, { method: 'post' });
                    return response?.data as iCreateZureoOrderResponse;
                } catch (error) {
                    // Handle error
                    throw error;
                }
            },
        };
    }
}

