import EntityCRUD, { iBaseEntity, iUsableFetch } from "./EntityCRUD";

export interface iConfig extends iBaseEntity {
  id: string;
  dataKey: string;
  value: string;
  description: string;
  dataType: number;
  slug: string;
  editable: boolean;
}

export interface iConfigModel extends iBaseEntity {
  id: string;
  data_key: string;
  value: string;
  description: string;
  data_type: number;
  slug: string;
  editable: boolean;
};

export default class Config extends EntityCRUD<iConfig> {
  static basePath = "/configs";

  static adapter(data: iConfig | iConfigModel) {
    if ((data as iConfigModel).data_key !== undefined) {
      const model = data as iConfigModel;
      return {
        ...model,
        dataKey: model.data_key,
        dataType: model.data_type,
      };
    } else {
      const item = data as iConfig;
      return {
        ...item,
        data_key: item.dataKey,
        data_type: item.dataType,
      };
    }
  }

  getBySlug(slug: string): iUsableFetch<iConfig> {
    const path = `${this.basePath}${encodeURIComponent(slug)}`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(path).then(
          (response) => Config.adapter(response.data)
        );
      },
    };
  }

  updateBySlug(slug: string, value: string): iUsableFetch<iConfig> {
    const path = `${this.basePath}${encodeURIComponent(slug)}`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(
          path, 
          { value: value }, 
          { method: "put" }
        ).then((response) => {
          return {
              ...response,
              data: Config.adapter(response.data),
          };
        });
      },
    }
  }
}
