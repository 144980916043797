import { WeekDays } from "lib/definitions";
import EntityCRUD, { iBaseEntity } from "./EntityCRUD";
import { iState } from "./State";
import { iCity } from "./City";

export interface iPickupPlace extends iBaseEntity {
  address: string;
  stateId: number | undefined;
  enabled: boolean;
  neighborhood: string;
  cityId: number;
  city: iCity;
  state?: iState;
  pickupPlaceTimings: iPickupPlaceTimings[];
  timings: iPickupPlaceTimings[];
  pickupPlaceTimesStr: string;
}

export interface iPickupPlaceTimings {
  id: number;
  day: WeekDays;
  timeStart: string;
  timeEnd: string;
}

export interface iPickupPlaceTimingModel {
  id: number;
  day: WeekDays;
  time_start: string;
  time_end: string;
}

export interface iPickupPlaceModel {
  id: number;
  address: string;
  state_id: number | undefined;
  enabled: number;
  neighborhood: string;
  city_id: number;
  city: iCity;
  state?: iState;
  pickupPlaceTimings: iPickupPlaceTimings[];
  pickup_place_timings: iPickupPlaceTimings[];
  pickup_place_times_str: string;
}

export default class PickupPlace extends EntityCRUD<iPickupPlace> {
  static basePath = "/pickup-places";

  static adapter(data: iPickupPlace | iPickupPlaceModel) {
    if ((data as iPickupPlaceModel).city_id !== undefined) {
      const model = data as iPickupPlaceModel;
      return {
        id: model.id.toString(),
        address: model.address,
        stateId: model.state_id,
        enabled: !!model.enabled,
        neighborhood: model.neighborhood,
        cityId: model.city_id,
        city: model.city,
        state: model.state,
        pickupPlaceTimings: model.pickupPlaceTimings,
        timings: model.pickup_place_timings,
        pickupPlaceTimesStr: model.pickup_place_times_str,
      };
    } else {
      const item = data as iPickupPlace;
      return {
        address: item.address,
        state_id: item.stateId,
        enabled: +item.enabled,
        neighborhood: item.neighborhood,
        city_id: item.cityId,
        city: item.city,
        state: item.state,
        pickupPlaceTimings: item.pickupPlaceTimings,
        pickup_place_timings: item.timings,
      };
    }
  }

  static pickupPlaceTimingAdapter(data: iPickupPlaceTimings | iPickupPlaceTimingModel) {
    if ((data as iPickupPlaceTimingModel).time_start !== undefined) {
      const model = data as iPickupPlaceTimingModel;
      return {
        id: model.id.toString(),
        day: model.day,
        timeStart: model.time_start,
        timeEnd: model.time_end,
      };
    } else {
      const items = data as iPickupPlaceTimings;
      return {
        id: items.id,
        day: items.day,
        timeStart: items.timeStart,
        timeEnd: items.timeEnd,
      };
    }
  }

  static create(data: iPickupPlace) {
    return EntityCRUD.createBase<iPickupPlace>(data);
  }
}
