import { ImageStatus } from "lib/definitions";
import moment from "moment";
import EntityCRUD, { iBaseEntity } from "./EntityCRUD";
import Order, { iOrder, iOrderModel, iProduct } from "./Order";

export interface iImage extends iBaseEntity {
  status: ImageStatus;
  url: string;
  urlThumb: string;
  product_data: string;
  product: iProduct;
  paperTypeId: string;
  printDate?: Date;
  order?: iOrder;
  qty?: number;
  printing: string;
}

export interface iImageModel extends iBaseEntity {
  status: number;
  url: string;
  url_thumb: string;
  product_data: string;
  paper_type_id: string;
  print_date?: string;
  order?: iOrderModel;
  qty?: number;
  printing: string;
}

export default class Image extends EntityCRUD<iImage> {
  static basePath = "/printing-images";

  static adapter(data: iImage | iImageModel) {
    if ((data as iImage).urlThumb !== undefined || (data as iImage).paperTypeId !== undefined) {
      const item = data as iImage;
      return {
        id: item.id,
        status: item.status,
        url: item.url,
        url_thumb: item.urlThumb,
        product_data: JSON.stringify(item.product_data),
        paper_type_id: item.paperTypeId,
        printing: item.printing,
        qty: item.qty,
        print_date:
          item.printDate && !isNaN(item.printDate.getTime())
            ? moment(item.printDate).format("YYYY-MM-DD")
            : undefined,
      };
    } else {
      const model = data as iImageModel;
      return {
        id: model.id,
        status: model.status,
        url: model.url,
        urlThumb: model.url_thumb,
        product:
          model.product_data && typeof model.product_data === "string"
            ? JSON.parse(model.product_data)
            : model.product_data,
        paperTypeId: model.paper_type_id,
        printing: model.printing,
        qty: model.qty,
        printDate: model.print_date && new Date(model.print_date),
        order: model.order && Order.adapter(model.order),
      };
    }
  }

  static create(data: iImage) {
    return EntityCRUD.createBase<iImage>(data);
  }
}
