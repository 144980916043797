export const esTranslations = {
  translation: {
    pageSize: {
      showing: "Mostrando",
      of: "de",
    },
    navigation: {
      main: "Principal",
      dashboard: "Página inicial",
      clients: "Clientes",
      orders: "Pedidos",
      duePayment: "Cobro pendiente",
      archived: "Archivados",
      boards: "Tableros",
      entryBoard: "Entrada",
      productionBoard: "Fabricación",
      shippingBoard: "Envíos",
      pickupBoard: "Retiros",
      prints: "Impresiones",

      shipping: "Envíos",
      awaitingCoordination: "Esperando coordinación",
      coordinatedDelivery: "Envío con coordinación",
      nonCoordinatedDelivery: "Envío sin coordinación",

      config: "Configuración",
      deliveryDates: "Fecha y horas de envío",
      shippingCosts: "Costos de envío",
      pickups: "Puntos de retiro",
      transporters: "Transportistas",
      emailTemplates: "Plantillas de correo",
      integrations: "Integraciones",
      invoice: "Facturación",
      whatsapp: "WhatsApp",

      list: "Listado",
      create: "Crear",
    },
    dashboard: {
      title: "Módulo de gestión de pedidos",
      monthOrders: "Pedidos del mes",
      monthRevenue: "Ingresos del mes",
      ordersInProcess: "Pedidos en proceso",
      ordersDelivered: "Pedidos entregados",
    },
    clients: {
      clients: "Clientes",
      general: "Información general",
      aditional: "Información adicional",
      name: "Nombre",
      email: "Email",
      phone: "Teléfono",
      status: "Estado",
      discount: "Descuento",
      confirmed: "Confirmado",
      pending: "Pendiente",
      client: "Cliente",
      noClient: "Sin cliente",
      password: "Contraseña",
      birthday: "Fecha de nacimiento",
      message: "Mensaje",
    },
    orders: {
      orders: "Pedidos",
      dateTime: "Fecha y horas",
      status: "Estado",
      total: "Total",
      receiver: "Receptor",
      address: "Dirección",
      neighborhood: "Barrio",
      city: "Ciudad",
      state: "Departamento",
      transporter: "Transportista",
      duePayment: "Debe",
      parcialPayment: "Pago parcial",
      order: "Pedido",
      invoiceModalTitle: "Facturación Zureo",
      dueModalTitle: "Orden con saldo pendiente",
      dueModalText1: "tiene un saldo pendiente de",
      dueModalText2: "¿Ya fue abonado?",
      selectAll: "Seleccionar todos",
      selected_one: "{{count}} pedido seleccionado",
      selected_other: "{{count}} pedidos seleccionados",

      general: "Información general",
      client: "Cliente (registrado)",
      searchClient: "Buscar cliente...",
      sellingDate: "Fecha de venta",
      fromDate: "Desde",
      toDate: "Hasta",
      date: "Fecha",
      notes: "Notas",
      name: "Nombre",
      lastName: "Apellido",
      shipment: "Envío",
      pickup: "Retiro",
      departure: "Despacho",
      delivery: "Entrega",
      pickupPlace: "Punto de retiro",
      notApplyPickupPlace: "No aplica (con envío)",
      pickupDate: "Fecha de retiro",
      departureDate: "Fecha de despacho",
      deliveryDate: "Fecha de entrega",
      receptor: "Receptor",
      invoiceInfo: "Información de facturación",
      companyName: "Razón social",
      companyIdentifier: "RUT",
      companyAddress: "Dirección fiscal",
      paymentInfo: "Información de pago",
      paymentStatus: "Estado",
      paymentId: "ID de transacción",
      paymentMethod: "Medio de pago",
      installments: "Cantidad de pagos",
      changeToPickup: "¿Está seguro de cambiar a retiro?",
      subtotal: "Subtotal",
      coupon: "Cupón",
      discount: "Descuento",
      deliveryDates: "Fechas de entrega",
      deliveryTimes: "Horarios de entrega",

      statusPending: "Pendiente",
      statusPaymentFailed: "Pago fallido",
      statusAwaitingPayment: "Esperando pago",
      statusJoined: "Ingresado",
      statusInprocess: "En proceso",
      statusAlmostFulfilled: "Proceso avanzado",
      statusAwaitingCoordination: "Esperando coordinacion",
      statusAwaitingPickup: "Esperando retiro",
      statusAwaitingNonCoordinatedShipment: "Esperando despacho 🚚",
      statusAwaitingCoordinatedShipment: "Esperando despacho 🚚 🕗",
      statusShipped: "En camino",
      statusCompleted: "Entregado",

      generalTab: "General",
      productsTab: "Productos",
      activityTab: "Actividad",
    },
    products: {
      product: "Producto",
      products: "Productos",
      molding: "Moldura",
      size: "Tamaño",
      width: "Ancho",
      height: "Alto",
      frameSize: "Med. marco",
      orientation: "Orientación",
      glass: "Vidrio",
      margin: "Margen",
      marginMaterial: "Material",
      marginWidth: "Ancho",
      marginColor: "Color",
      imageSize: "Med. inter.",
      images: "Imágenes",
      price: "Precio",
      notes: "Notas",
      vertical: "Vertical",
      horizontal: "Horizontal",
      sheets: "Láminas",
      clockFace: "Esfera",
      clockHands: "Agujas",
    },
    activity: {
      dateAndTime: "Fecha y hora",
      event: "Evento",
      newValue: "Nuevo valor",
      user: "Usuario",
      noActivity: "Sin actividad del pedido",
    },
    images: {
      images: "Impresiones",
      new: "Ingresado",
      sent: "Enviado a imprimir",
      printed: "Impreso",
      image: "Imagen",
      quantity: "Cantidad",
      size: "Medidas",
      status: "Estado",
      paperType: "Tipo de papel",
      printDate: "Fecha de envío",
      width: "Ancho",
      height: "Alto",
    },
    pickups: {
      pickups: "Puntos de retiro",
      pickup: "Punto de retiro",
      address: "Dirección",
      neighborhood: "Barrio",
      city: "Ciudad",
      state: "Departamento",
      workDays: "Días de operación",
      startTime: "Hora de inicio",
      endTime: "Hora de fin",
      daysAndTimes: "Dias y horarios",
    },
    transporters: {
      transporters: "Transportistas",
      transporter: "Transportista",
      name: "Nombre",
      phone: "Teléfono",
      cities: "Ciudades",
    },
    states: {
      states: "Costos de envío por departamento",
      state: "Departamento",
      shippingCost: "Costo de envío",
    },
    emailTemplates: {
      emailTemplate: "Plantilla de correo",
      emailTemplates: "Plantillas de correo",
      id: "Id",
      type: "Tipo",
      subject: "Asunto",
      variable: "Variable",
      body: "Cuerpo",
      link: "Enlace",
      displayText: "Texto a mostrar",

      typeStarted: "Pedido iniciado",
      typePaymentFailed: "Pago fallido",
      typeRegisteredAndAwaitingPayment: "Pedido ingresado, esperando pago",
      typeAwaitingPayComplete: "Falta completar pago",
      typeRegisteredAndPayment: "Pedido ingresado y pago",
      typePayReceipt: "Pago recibido",
      typeAwaitingPickup: "Esperando retiro",
      typeCoordinateDelivery: "Coordinar envío",
      typeShipped: "Pedido en camino",
      typeSatisfactionPoll: "Encuesta de satisfaccion",
    },
    notifications: {
      notifications: "Notificaciones",
      notification: "Notificación",
      read: "Leída",
      unread: "No leídas",
      readAll: "Leer todas",
      deleteAll: "Eliminar todas",
      allRead: "Notificaciones leídas",
      allDeleted: "Notificaciones eliminadas",
      seeAll: "Ver todas las notificaciones",
      new: "nuevas",
      id: "Id",
      title: "Título",
      message: "Mensaje",
    },
    whatsapp: {
      title: "Configuración de WhatsApp",
      areaCode: "Código de área telefónica",
      message: "Mensaje",
      configUpdated: "Configuración de WhatsApp actualizada",
    },
    actions: {
      edit: "Editar",
      create: "Crear",
      delete: "Eliminar",
      add: "Agregar",
      preview: "Previsualizar",
      invoiced: "Facturado",
      duplicate: "Duplicar",
      archive: "Archivar",
      unarchive: "Desarchivar",
      yes: "Si",
      no: "No",
      print: "Imprimir",
      invoice: "Facturar",
      view: "Ver",
      save: "Guardar",
      cancel: "Cancelar",
      new: "Nuevo",
      all: "Todos",
      none: "Ninguno",
      filter: "Filtrar",
      upload: "Cargar",
      download: "Descargar",
      downloadShipment: "Descargar envíos",
      logOut: "Cerrar sesión",
      disable: "Deshabilitar",
      search: "Buscar",
      send: "Enviar",
      settings: "Preferencias",
      change: "Cambiar",
    },
    messages: {
      confirmDelete: "¿Está seguro de eliminar ",
      notSelected: "No seleccionado",
      from: "Desde",
      to: "Hasta",
      warning: "Advertencia",
      active: "Activo",
      inactive: "Inactivo",
      enabled: "Habilitado",
      currency: "$",
      invalidCredencials: "Credenciales no válidas",
      operationError: "Error en la operación",
      successOperation: "Operación exitosa",
      updated: "actualizado",
      created: "creado",
      loading: "Cargando",
      notFound_male: "no encontrado",
      notFound_female: "no encontrada",
      successDelete_male: "eliminado con exito",
      successDelete_female: "eliminada con exito",
    },
    badges: {
      pickup: "Retiro",
      shipment: "Envío",
      problem: "Problema",
      priority: "Prioritario",
      archived: "Archivado",
    },
    weekDays: {
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
    },
    yearMonths: {
      january: "Enero",
      february: "Febrero", 
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Setiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
    }
  },
}