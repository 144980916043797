import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iUser extends iBaseEntity {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  status: boolean;
  createdOn: Date;
  birthDate: string | null;
  discount: number;
  address?: iAddress;
}

export interface iAddressCustomer {
  name?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
}

export interface iAddress extends iAddressCustomer {
  id?: number;
  line1: string;
  line2: string;
  cityId: string;
  countryId?: string;
  customerId?: string;
  main?: boolean;
  stateId: string;
  state?: iState;
  neighborhood?: string;
}

export interface iAddressModel {
  id: number;
  line1: string;
  line2: string;
  city_id: string;
  country_id: string;
  customer_id: string;
  main: boolean;
  state_id: string;
  email: string;
  name: string;
  last_name: string;
  telephone: string;
  shipping_cost?: iState;
  neighborhood: string;
}

export interface iStateModel {
  id: number;
  name: string;
  shipping_cost: number;
  enabled: boolean;
}

export interface iState {
  id: number;
  placeName: string;
  cost: number;
  enabled: boolean;
}

export interface iUserModel {
  id: number;
  name: string;
  last_name: string;
  email: string;
  telephone: string;
  status: number;
  created_on?: string;
  birth_date: string | null;
  discount: number;
  password: string;
  address?: iAddressModel;
}

export default class User extends EntityCRUD<iUser> {
  static basePath = "/customers";

  static adapter(data: iUser | iUserModel) {
    if ((data as iUserModel).name !== undefined) {
      const model = data as iUserModel;
      return {
        id: model.id.toString(),
        firstName: model.name,
        lastName: model.last_name,
        email: model.email,
        telephone: model.telephone,
        status: !!model.status,
        createdOn: new Date(model.created_on!),
        birthDate: model.birth_date && model.birth_date,
        discount: model.discount,
        address:
          model.address && User.addressAdapter(model.address as iAddressModel),
      } as iUser;
    } else {
      const item = data as iUser;
      return {
        id: +item.id,
        name: item.firstName,
        last_name: item.lastName,
        email: item.email,
        telephone: item.telephone,
        status: item.status ? 1 : 0,
        birth_date: item.birthDate,
        discount: item.discount,
        // @TODO: remove
        password: "12131jasdbasvd%^*&",
        address:
          // item.address && JSON.stringify(Order.addressAdapter(item.address)),
          item.address && User.addressAdapter(item.address),
      } as iUserModel;
    }
  }

  static addressAdapter(data: iAddress | iAddressModel) {
    if ((data as iAddressModel).last_name !== undefined) {
      const model = data as iAddressModel;
      return {
        id: model.id,
        line1: model.line1,
        line2: model.line2,
        cityId: model.city_id,
        countryId: model.country_id,
        customerId: model.customer_id,
        main: model.main,
        stateId: model.state_id,
        email: model.email,
        name: model.name,
        lastName: model.last_name,
        telephone: model.telephone,
        neighborhood: model.neighborhood,
        shippingCost: model.shipping_cost && User.statesAdapter(model.shipping_cost),
      } as iAddress;
    } else {
      const item = data as iAddress;
      return {
        id: item.id,
        line1: item.line1,
        line2: item.line2,
        city_id: item.cityId,
        country_id: item.countryId,
        customer_id: item.customerId,
        main: item.main,
        state_id: item.stateId,
        email: item.email,
        name: item.name,
        last_name: item.lastName,
        telephone: item.telephone,
        neighborhood: item.neighborhood,
        state: item.state && User.statesAdapter(item.state),
      } as iAddressModel;
    }
  }

  static statesAdapter(data: iState | iStateModel) {
    if ((data as iStateModel).name !== undefined) {
      const model = data as iStateModel;
      return {
        id: model.id,
        placeName: model.name,
        cost: model.shipping_cost,
        enabled: model.enabled,
      } as iState;
    } else {
      const item = data as iState;
      return {
        id: item.id,
        name: item.placeName,
        shipping_cost: item.cost,
        enabled: item.enabled,
      } as iStateModel;
    }
  }

  static create(data: iUser) {
    return EntityCRUD.createBase<iUser>(data);
  }
}
