import fetch from "lib/fetch";
import { iUsableFetch } from "./EntityCRUD";
import { iProductsList } from "./Order";

export interface iProductRequest {
  stateId?: number;
  products: iProductsList;
}

export interface iProductRequestModel {
  state_id?: number;
  products_json: iProductsList;
}

export interface iProductPrice {
  totalAmount: number;
}

export enum CollectionType {
  Clock = "clock-collections",
  Title = "title-collections",
  Mirror = "mirror-collections",
  Picture = "picture-collections",
  PictureFrame = "picture-frame-collections",
  SheetCollection = "sheet-collections",
}

export type ClockCollection = {
  sizes: NodeJS.Dict<string>;
  clockSpheres: NodeJS.Dict<string>;
  clockHands: NodeJS.Dict<string>;
  glasses: NodeJS.Dict<string>;
  moldingColors: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
};

export type TitleCollection = {
  titleImages: NodeJS.Dict<string>;
  titleImagesWithoutName: NodeJS.Dict<string>;
  orientations: NodeJS.Dict<string>;
  glasses: NodeJS.Dict<string>;
  moldingColors: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
  marginMaterials: NodeJS.Dict<string>;
  marginColors: NodeJS.Dict<string>;
  marginWidths: NodeJS.Dict<string>;
};

export type MirrorCollection = {
  sizes: NodeJS.Dict<string>;
  sizesWithoutCm: NodeJS.Dict<string>;
  orientations: NodeJS.Dict<string>;
  moldingColors: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
};

export type PictureCollection = {
  glasses: NodeJS.Dict<string>;
  moldingColors: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
  marginMaterials: NodeJS.Dict<string>;
  marginColors: NodeJS.Dict<string>;
  marginWidths: NodeJS.Dict<string>;
};

export type PictureFrameCollection = {
  orientations: NodeJS.Dict<string>;
  sizes: NodeJS.Dict<string>;
  sizesizesWithoutCms: NodeJS.Dict<string>;
  glasses: NodeJS.Dict<string>;
  moldingColors: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
};

export type SheetCollection = {
  orientations: NodeJS.Dict<string>;
  glasses: NodeJS.Dict<string>;
  moldingColorsWithCode: NodeJS.Dict<string>;
  marginMaterials: NodeJS.Dict<string>;
  marginColors: NodeJS.Dict<string>;
  marginWidths: NodeJS.Dict<string>;
  sheets: NodeJS.Dict<string>;
};

export type CollectionData = Partial<
  ClockCollection &
    TitleCollection &
    MirrorCollection &
    PictureCollection &
    PictureFrameCollection &
    SheetCollection
>;

export default class Product {
  basePath = "/products";

  adapter(data: iProductRequest): iProductRequestModel {
    return {
      state_id: data.stateId,
      products_json: data.products,
    };
  }

  price(data: iProductRequest): iUsableFetch<iProductPrice> {
    return {
      key: this.basePath,
      fetcher: () => {
        return fetch(this.basePath, this.adapter(data), {
          method: "post",
        }).then((r) => r.data);
      },
    };
  }

  collection(type: CollectionType): iUsableFetch<CollectionData> {
    const path = `${this.basePath}/${type}`;

    return {
      key: path,
      fetcher: () => {
        return fetch(path).then((r) => r.data);
      },
    };
  }

  // Upload product image to s3
  uploadImage(formData: FormData) {
    const path = `${this.basePath}/upload-product-image`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await fetch(path, formData, { method: "post" });
          return response.data;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }

  // Delete product image from s3
  deleteImage(formData: FormData) {
    const path = `${this.basePath}/delete-product-image`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await fetch(path, formData, { method: "post" });
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }
}
