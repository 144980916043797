import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iState extends iBaseEntity {
  id: string;
  name: string;
  shipping_cost: number;
  enabled: boolean;
}

export default class State extends EntityCRUD<iState> {
  static basePath = "/states";

  static create(data: iState) {
    return EntityCRUD.createBase<iState>(data);
  }
}
