import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iNotification extends iBaseEntity {
  id: string;
  type: string;
  notificationFor: string;
  title: string;
  message: string;
  isRead: number;
  createdAt: string;
  updatedAt: string;
}

export interface iNotificationModel {
  id: string;
  type: string;
  notification_for: string;
  title: string;
  message: string;
  is_read: number;
  created_at: string;
  updated_at: string;
}

export default class Notification extends EntityCRUD<iNotification> {
  static basePath = "/notifications";

  static adapter(data: iNotification | iNotificationModel) {
    if ((data as iNotificationModel).message !== undefined) {
      const model = data as iNotificationModel;
      return {
        id: model.id?.toString(),
        type: model.type,
        notificationFor: model.notification_for,
        title: model.title,
        message: model.message,
        isRead: model.is_read,
        createdAt: model.created_at,
        updatedAt: model.updated_at
      };
    } else {
      const item = data as iNotification;
      return {
        id: item.id,
        type: item.type,
        notification_for: item.notificationFor,
        title: item.title,
        message: item.message,
        is_read: item.isRead,
        created_at: item.createdAt,
        updated_at: item.updatedAt
      };
    }
  }

  static create(data: iNotification) {
    return EntityCRUD.createBase<iNotification>(data);
  }

  // mark read/unread notification
  markRead(isRead: boolean) {
    const path = `${Notification.basePath}/${this.id}/mark-read`;
  
    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, { is_read: isRead }, { method: "put" });
          return {
            ...response,
            data: Notification.adapter(response.data),
          };
        } catch (error) {
          // Handle error
          throw error;
        }
      },
    };
  }

  // mark read all notifications
  markReadAll(ids: Array<number>) {
    const path = `${Notification.basePath}/mark-read-all`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, { ids }, { method: "post" });
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }

  // delete all notifications
  deleteAll(ids: Array<number>) {
    const path = `${Notification.basePath}/delete-all`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, { ids }, { method: "post" });
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }
}
