export const enTranslations = {
  translation: {
    pageSize: {
      showing: "Showing",
      of: "of",
    },
    navigation: {
      main: "Main",
      dashboard: "Dashboard",
      clients: "Clients",
      orders: "Orders",
      duePayment: "Due payment",
      archived: "Archived",
      boards: "Boards",
      entryBoard: "Entry",
      productionBoard: "Production",
      shippingBoard: "Shipping",
      pickupBoard: "Pickup",
      prints: "Prints",

      shipping: "Shipping",
      awaitingCoordination: "Awaiting coordination",
      coordinatedDelivery: "Coordinated delivery",
      nonCoordinatedDelivery: "Non-coordinated delivery",

      config: "Configuration",
      deliveryDates: "Delivery dates",
      shippingCosts: "Shipping costs",
      pickups: "Pickup places",
      transporters: "Transporters",
      emailTemplates: "Email templates",
      integrations: "Integrations",
      invoice: "Invoice",
      whatsapp: "WhatsApp",

      list: "List",
      create: "Create",
    },
    dashboard: {
      title: "Orders management module",
      monthOrders: "Orders this month",
      monthRevenue: "Revenue this month",
      ordersInProcess: "Orders in process",
      ordersDelivered: "Orders delivered",
    },
    clients: {
      clients: "Clients",
      general: "General information",
      aditional: "Aditional information",
      name: "Name",
      email: "Email",
      phone: "Phone",
      status: "Status",
      discount: "Discount",
      confirmed: "Confirmed",
      pending: "Pending",
      client: "Client",
      noClient: "No client",
      password: "Password",
      birthday: "Birthday",
      message: "Message",
    },
    orders: {
      orders: "Orders",
      dateTime: "Date and time",
      status: "Status",
      total: "Total",
      receiver: "Receiver",
      address: "Address",
      neighborhood: "Neighborhood",
      city: "City",
      state: "State",
      transporter: "Transporter",
      duePayment: "Due payment",
      parcialPayment: "Partial payment",
      order: "Order",
      invoiceModalTitle: "Zureo Invoice",
      dueModalTitle: "Order with pending balance",
      dueModalText1: "has a pending balance of",
      dueModalText2: "Has it been paid?",
      selectAll: "Select all",
      selected_one: "{{count}} order selected",
      selected_other: "{{count}} orders selected",

      general: "General information",
      client: "Client (registered)",
      searchClient: "Search client...",
      sellingDate: "Selling date",
      fromDate: "From",
      toDate: "To",
      date: "Date",
      notes: "Notes",
      name: "Name",
      lastName: "Last name",
      shipment: "Shipping",
      pickup: "Pickup",
      departure: "Departure",
      delivery: "Delivery",
      pickupPlace: "Pickup point",
      notApplyPickupPlace: "Does not apply (with shipping)",
      pickupDate: "Pickup date",
      departureDate: "Departure date",
      deliveryDate: "Delivery date",
      receptor: "Receiver",
      invoiceInfo: "Invoice information",
      companyName: "Company",
      companyIdentifier: "Identifier",
      companyAddress: "Fiscal address",
      paymentInfo: "Payment information",
      paymentStatus: "Payment status",
      paymentId: "Transaction ID",
      paymentMethod: "Payment method",
      installments: "Number of installments",
      changeToPickup: "Are you sure you want to change to pickup?",
      subtotal: "Subtotal",
      coupon: "Coupon",
      discount: "Discount",
      deliveryDates: "Delivery dates",
      deliveryTimes: "Delivery times",

      statusPending: "Pending",
      statusPaymentFailed: "Payment failed",
      statusAwaitingPayment: "Awaiting payment",
      statusJoined: "Entered",
      statusInprocess: "In process",
      statusAlmostFulfilled: "Advanced process",
      statusAwaitingCoordination: "Awaiting coordination",
      statusAwaitingPickup: "Awaiting pickup",
      statusAwaitingNonCoordinatedShipment: "Awaiting dispatch 🚚",
      statusAwaitingCoordinatedShipment: "Awaiting dispatch 🚚 🕗",
      statusShipped: "Shipped",
      statusCompleted: "Delivered",

      generalTab: "General",
      productsTab: "Products",
      activityTab: "Activity",
    },
    products: {
      product: "Product",
      products: "Products",
      molding: "Frame",
      size: "Size",
      width: "Width",
      height: "Height",
      frameSize: "Frame size",
      orientation: "Orientation",
      glass: "Glass",
      margin: "Margin",
      marginMaterial: "Material",
      marginWidth: "Width",
      marginColor: "Color",
      imageSize: "Image size",
      images: "Images",
      price: "Price",
      notes: "Notes",
      vertical: "Vertical",
      horizontal: "Horizontal",
      sheets: "Sheets",
      clockFace: "Clock face",
      clockHands: "Clock hands",
    },
    activity: {
      dateAndTime: "Date and time",
      event: "Event",
      newValue: "New value",
      user: "User",
      noActivity: "No order activy available",
    },
    images: {
      images: "Prints",
      new: "Entered",
      sent: "Sent to print",
      printed: "Printed",
      image: "Image",
      quantity: "Quantity",
      size: "Size",
      status: "Status",
      paperType: "Paper type",
      printDate: "Print date",
      width: "Width",
      height: "Height",
    },
    pickups: {
      pickups: "Pickup places",
      pickup: "Pickup place",
      address: "Address",
      neighborhood: "Neighborhood",
      city: "City",
      state: "State",
      workDays: "Operating days",
      startTime: "Start time",
      endTime: "End time",
      daysAndTimes: "Days and times",
    },
    transporters: {
      transporters: "Transporters",
      transporter: "Transporter",
      name: "Name",
      phone: "Phone",
      cities: "Cities",
    },
    states: {
      states: "Shipping costs by state",
      state: "State",
      shippingCost: "Shipping cost",
    },
    emailTemplates: {
      emailTemplate: "Email template",
      emailTemplates: "Email templates",
      id: "ID",
      type: "Type",
      subject: "Subject",
      variable: "Variable",
      body: "Body",
      link: "Link",
      displayText: "Display text",

      typeStarted: "Order started",
      typePaymentFailed: "Payment failed",
      typeRegisteredAndAwaitingPayment: "Order registered, awaiting payment",
      typeAwaitingPayComplete: "Payment incomplete",
      typeRegisteredAndPayment: "Order registered and paid",
      typePayReceipt: "Payment received",
      typeAwaitingPickup: "Awaiting pickup",
      typeCoordinateDelivery: "Coordinate delivery",
      typeShipped: "Order shipped",
      typeSatisfactionPoll: "Satisfaction survey",
    },
    notifications: {
      notifications: "Notifications",
      notification: "Notification",
      read: "Read",
      unread: "Not read",
      readAll: "Read all",
      deleteAll: "Delete all",
      allRead: "Notifications read",
      allDeleted: "Notifications deleted",
      seeAll: "See all notifications",
      new: "new",
      id: "Id",
      title: "Title",
      message: "Message",
    },
    whatsapp: {
      title: "WhatsApp configuration",
      areaCode: "Phone area code",
      message: "Message",
      configUpdated: "WhastApp configuration updated",
    },
    actions: {
      edit: "Edit",
      create: "Create",
      delete: "Delete",
      add: "Add",
      preview: "Preview",
      invoiced: "Invoiced",
      duplicate: "Duplicate",
      archive: "Archive",
      unarchive: "Unarchive",
      yes: "Yes",
      no: "No",
      print: "Print",
      invoice: "Invoice",
      view: "View",
      save: "Save",
      cancel: "Cancel",
      new: "New",
      all: "All",
      none: "None",
      filter: "Filter",
      upload: "Upload",
      download: "Download",
      downloadShipment: "Download shipments",
      logOut: "Log out",
      disable: "Disable",
      search: "Search",
      send: "Send",
      settings: "Settings",
      change: "Change",
    },
    messages: {
      confirmDelete: "Are you sure you want to delete ",
      notSelected: "Not selected",
      from: "From",
      to: "To",
      warning: "Warning",
      active: "Active",
      inactive: "Inactive",
      enabled: "Enabled",
      currency: "$",
      invalidCredencials: "Invalid credentials",
      operationError: "Error in the operation",
      successOperation: "Operation successful",
      updated: "Updated",
      created: "Created",
      loading: "Loading",
      notFound_male: "not found",
      notFound_female: "not found",
      successDelete_male: "successfully deleted",
      successDelete_female: "successfully deleted",
    },
    badges: {
      pickup: "Pickup",
      shipment: "Shipment",
      problem: "Problem",
      priority: "Priority",
      archived: "Archived",
    },
    weekDays: {
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
    },
    yearMonths: {
      january: "January",
      february: "February", 
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    }
  },
}
