import { tListingFilter, tListingFilterValue } from "lib/definitions";
import qs from "qs";
import fetch, { iEnmarcoWrapper } from "../fetch";
import { mayus } from "Helpers/Utils";

export interface iBaseEntity {
  id?: string;
}

export interface iBaseEntityWithName extends iBaseEntity{
    name?: string;
}

export function getEntityName <T extends iBaseEntityWithName>(
  list: Array<T> | null, 
  id: number
): string | null {
    const result = list?.find(item => Number(item.id) === id);
    return result?.name ? mayus(result.name) : null;
}

export interface iPaginatedList<T> extends iEnmarcoWrapper<Array<T>> {
  from?: number;
  last_page?: number;
  per_page?: number | string; // ¯\_(ツ)_/¯
  to?: number;
  total?: number;
}

export interface iUsableFetch<T> {
  key: string;
  fetcher: () => Promise<T>;
}

export default class EntityCRUD<T extends iBaseEntity> {
  static basePath = "";
  id: string;
  basePath: string;
  fetch = fetch;

  constructor(id: string) {
    const entity = (this as any).constructor;

    this.id = id;
    this.basePath = `${entity.basePath}/${this.id}`;
  }

  static createBase<T extends iBaseEntity>(
    data: T,
    _entity?: any
  ): iUsableFetch<T> {
    const entity = _entity || (this as any).constructor;
    const adapter = entity.adapter || ((v: T) => v);
    const result = {
      key: "",
      fetcher: async () => {
        const response = (await fetch(
          entity.basePath,
          adapter(data)
        )) as iEnmarcoWrapper<T>;
        if (response.success) {
          const { data: item } = response;
          result.key = `${entity.basePath}/${item.id}`;
          return adapter(item);
        }
      },
    };

    return result;
  }

  create(data: T): iUsableFetch<T> {
    const entity = (this as any).constructor;
    return EntityCRUD.createBase(data, entity);
  }

  list(_query?: tListingFilter): iUsableFetch<iPaginatedList<T>> {
    const entity = (this as any).constructor;
    const adapter = entity.adapter || ((v: T) => v);
    const query = { ..._query } as tListingFilter;

    function filter(key: string, value: tListingFilterValue) {
      if ((key === "filters" || key === "filterByDates") && value) {
        // return value;
        return Object.keys(value as object)
          .map((k) => {
            // @ts-ignore:7053
            const v = value[k] as tListingFilterValue;
            return `${k}=${Array.isArray(v) ? v.join("|") : v}`;
          })
          .join(",");
      } else if (key === "per_page" && value) {
        return value !== -1 ? value : "all";
      }
      return value;
    }

    // if (query.limit === 0 || query.limit === "0") {
    //   delete query.limit;
    //   delete query.page;
    // }

    const isMock = entity.basePath.startsWith("~");
    if (isMock) {
      delete query.per_page;
    }

    const path = `${entity.basePath}${qs.stringify(query, {
      filter,
      addQueryPrefix: true,
    })}`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(path).then((response) => {
          return {
            ...response,
            data: response.data.map((item: any) => adapter(item)),
          };
        });
        // return this.fetch(path) as Promise<TList>;
      },
    };
  }

  get(): iUsableFetch<T> {
    const entity = (this as any).constructor;
    const adapter = entity.adapter || ((v: T) => v);

    return {
      key: this.basePath,
      fetcher: () => {
        return this.fetch(this.basePath).then(
          (response) => adapter(response.data) as T
        );
      },
    };
  }

  update(data: Partial<T>): iUsableFetch<T> {
    const entity = (this as any).constructor;
    const adapter = entity.adapter || ((v: T) => v);

    return {
      key: this.basePath,
      fetcher: () => {
        return this.fetch(this.basePath, adapter(data), {
          method: "put",
        }).then((response) => adapter(response.data) as T);
      },
    };
  }

  delete(): iUsableFetch<T> {
    const key = this.basePath;

    return {
      key,
      fetcher: () => {
        return this.fetch(key, undefined, {
          method: "delete",
        }) as Promise<T>;
      },
    };
  }
}
