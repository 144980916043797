import { api } from "lib/api";
import { iConfig } from "lib/api/Config";
import useSWR from "swr";

const useLanguage = () => {

    const apiFetchConfig = api.config().getBySlug('language-code');
    const { data: lng } = useSWR<iConfig>(
	    apiFetchConfig.key,
	    apiFetchConfig.fetcher,
	);

    return lng;
}

export default useLanguage;