import EntityCRUD, { iBaseEntityWithName, iUsableFetch, iPaginatedList } from "./EntityCRUD";

export interface iPaperType extends iBaseEntityWithName {
}

export default class PaperType extends EntityCRUD<iPaperType> {
  static basePath = "/paper-types";
}


