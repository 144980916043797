import { WeekDays } from "lib/definitions";
import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iTiming {
  time_start: string;
  time_end: string;
}

export interface iDeliveryDayTiming extends iBaseEntity {
  id: string;
  day: WeekDays;
  time_end: string;
  time_start: string;
  timings: iTiming[];
}

export default class DeliveryDayTiming extends EntityCRUD<iDeliveryDayTiming> {
  static basePath = "/delivery-day-timings";

  static create(data: iDeliveryDayTiming) {
    return EntityCRUD.createBase<iDeliveryDayTiming>(data);
  }

  deleteByDay(day: WeekDays) {
    const path = "/delete-by-day";

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, { day }, { method: "delete" });
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }

}