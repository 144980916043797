import City, { iCity } from "./City";
import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iTransporter extends iBaseEntity {
  id: string;
  name: string;
  telephone: string;
  enabled: boolean;
  city?: iCity;
  cityId: string;
  cities?: Array<string | iCity>;
}

export type iTransporterModel = Omit<iTransporter, "cityId"> & {
  city_id: string;
};

export default class Transporter extends EntityCRUD<iTransporter> {
  static basePath = "/transporters";

  static adapter(data: iTransporter | iTransporterModel) {
    if ((data as iTransporterModel).city_id !== undefined) {
      const model = data as iTransporterModel;
      return {
        ...model,
        cityId: model.city_id,
        cities: model.cities?.map((city) =>
          typeof city === "string" ? city : City.adapter(city)
        ),
      };
    } else {
      const item = data as iTransporter;
      return {
        ...item,
        city_id: item.cityId,
      };
    }
  }

  static create(data: iTransporter) {
    return EntityCRUD.createBase<iTransporter>(data);
  }
}
