import EntityCRUD, { iBaseEntity } from "./EntityCRUD";

export interface iDeliveryOffDay extends iBaseEntity {
    off_day: string;
}

export default class DeliveryOffDay extends EntityCRUD<iDeliveryOffDay> {
    static basePath = "/delivery-off-days";

    static create(data: iDeliveryOffDay) {
        return EntityCRUD.createBase<iDeliveryOffDay>(data);
    }

}