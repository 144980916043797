import jwt, { JwtPayload } from "jsonwebtoken";

const { REACT_APP_JWT_PUBLIC_KEY: publicKey } = process.env;

const LOCAL_STORAGE_AUHT_KEY = "enmrc";

interface CustomJwtPayload extends JwtPayload {
  nbf?: number;
}

export function storeAuth(auth: string) {
  window.localStorage.setItem(LOCAL_STORAGE_AUHT_KEY, auth);
}

export function clearAuth() {
  window.localStorage.removeItem(LOCAL_STORAGE_AUHT_KEY);
}

export function getAuth() {
  return window.localStorage.getItem(LOCAL_STORAGE_AUHT_KEY) || "";
}

export function getAuthStatus() {
  const token = getAuth();

  try {
    const decodedToken = jwt.decode(token) as CustomJwtPayload | null;

    if (decodedToken && decodedToken.nbf) {
      const nbfTimestamp = decodedToken.nbf;
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (currentTimestamp < nbfTimestamp) {
        // Token is not yet valid
        return {
          data: null,
          token: null,
          isValid: false,
          error: "Token is not yet active",
        };
      }
    }

    const data = jwt.verify(token, publicKey!) as CustomJwtPayload;

    return {
      data,
      token,
      isValid: true,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      token: null,
      isValid: false,
      error: (error as Error).message,
    };
  }
}
