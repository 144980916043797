//Function created to fix the cities Upper problem. Feature 49
//Modified searching bugs.
export const mayus = (aString: String) => {
    if (!aString) {
        return '';
    }
    const words = aString.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
    }
    return words.join(" ");
}

/**
 * Removes leading zeros from a string, if any.
 * Example: "0000123" becomes "123".
 *
 * @param {string} str The string to remove leading zeros from.
 * @return {string} The string with leading zeros removed.
 */
export function removeLeadingZeros(str: string) {
    return str.replace(/^0+/, '');
}