import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { esTranslations } from 'lib/translations/es.ts';
import { enTranslations } from 'lib/translations/en.ts';

i18n
  .use(initReactI18next)    // Initializes react-i18next
  .init({
    fallbackLng: 'es',      // Default language
    debug: false,           // Enable debugging during development
    interpolation: {
      escapeValue: false,   // React already escapes values
    },
    resources: {
        es: esTranslations,
        en: enTranslations
    },
  });

export default i18n;
