import EntityCRUD, { iBaseEntity, iUsableFetch } from "./EntityCRUD";

export interface iSiteImage extends iBaseEntity {
  fileName: string;
}

export interface iSiteImageModel extends iBaseEntity {
  file_name: string;
};

export default class SiteImage extends EntityCRUD<iSiteImage> {
  static basePath = "/site-images";

  static adapter(data: iSiteImage | iSiteImageModel) {
    if ((data as iSiteImageModel).file_name !== undefined) {
      const model = data as iSiteImageModel;
      return {
        ...model,
        fileName: model.file_name,
      };
    } else {
      const item = data as iSiteImage;
      return {
        ...item,
        file_name: item.fileName,
      };
    }
  }

  static create(data: iSiteImage) {
    return EntityCRUD.createBase<iSiteImage>(data);
  }

  getByName(name: string): iUsableFetch<iSiteImage> {
    return {
      key: `${this.basePath}${encodeURIComponent(name)}`,
      fetcher: () => {
        return this.fetch(`${this.basePath}${encodeURIComponent(name)}`).then(
          (response) => SiteImage.adapter(response)
        );
      },
    };
  }
}
