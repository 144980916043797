import { OrderStatus } from "lib/definitions";
import EntityCRUD, { iBaseEntity } from "./EntityCRUD";
import pickupPlace, {
  iPickupPlace,
  iPickupPlaceModel,
} from "./PickupPlace";
import User, { iUser, iUserModel } from "./User";
import Transporter, { iTransporter, iTransporterModel } from "./Transporter";
import OrderIntegrations, { iOrderIntegrations, iOrderIntegrationsModel } from "./OrderIntegrations";

export interface iProductsList {
  items: Array<iProduct>;
  totalAmount?: number;
  discount?: number;
  couponCode?: string;
}

export interface iOrder extends iBaseEntity {
  id: string;
  orderToken: string;

  status: OrderStatus;
  priority: boolean;
  withProblems: boolean;
  invoiced: boolean;
  archived_at?: Date;

  customerId?: number;
  addressId: string;
  pickupPlaceId?: string;
  shippingCost?: number;

  sellingDate: string;
  shipmentDate?: Date;
  // dueDate: Date;

  notes?: string;
  receptorName: string;
  receptorTelephone: string;

  transporterId: number;
  deliveryDate: string;
  deliveryTimeStart: string;
  deliveryTimeEnd: string;
  transactionType: number;
  approvalId?: string;

  cost?: number;
  tax: number;
  total: number;
  due?: number;
  locationCost?: number;

  products: iProductsList;
  payment?: iPayment;

  pickupPlace?: iPickupPlace;
  customer?: iUser;
  address?: iAddress;
  rut?: iRut;
  transporter?: iTransporter;
  orderIntegrations?: iOrderIntegrations[];
  orderLogs?: iOrderLogs;
}

export interface iOrderPriorityUpdate {
  priority?: iOrder["priority"];
  withProblems?: iOrder["withProblems"];
}

export interface iProduct {
  model: string;
  type: string;
  imgUrl?: string;
  imgThumbUrl?: string;

  width?: string;
  height?: string;
  orientation?: string;
  sizeId?: string;
  titleImageId?: string;
  titleImageSize?: iTitleImageSize;

  marginId: string;
  marginWidth: string;
  marginColorId: string;
  marginMaterial: string;
  marginMaterialId: string;

  moldingColorId: string;
  moldingWidth: string;

  glassId: string;
  cartQty: string;
  imageType?: string;

  clockSphereId?: string;
  clockHandId?: string;
  orderPrice?: string;

  observations?: string;
  sheetIds?: Array<number>;
  name?: string;
}

export interface iTitleImageSize {
  width: string;
  height: string;
}

export interface iProductTitle extends iProduct {
  titleImageId: string;
}

export interface iAddressCustomer {
  name: string;
  lastName: string;
  email: string;
  telephone: string;
}
export interface iAddress extends iAddressCustomer {
  id: number;
  line1: string;
  line2?: string;
  cityId: number;
  countryId: number;
  customerId: number;
  // main: 1;
  stateId: number;
  state?: iState;
  neighborhood?: string;
}

export interface iAddressModel {
  id: number;
  line1: string;
  line2?: string;
  city_id: number;
  country_id: number;
  customer_id: number;
  // main: 1;
  state_id: number;
  email: string;
  name: string;
  last_name: string;
  telephone: string;
  shipping_cost?: iState;
  neighborhood?: string;
}

export interface iStateModel {
  id: number;
  name: string;
  shipping_cost: number;
  enabled: boolean;
}

export interface iState {
  id: number;
  placeName: string;
  cost: number;
  enabled: boolean;
}

export interface iRutModel {
  id: number;
  address: string;
  order_id: number;
  rut: string;
  business_name: string;
}

export interface iRut {
  id: number;
  invoiceAddress: string;
  orderId: number;
  invoiceRut: string;
  invoiceName: string;
}

export interface iOrderLogsModel {
  id: string;
  order_id: number;
  created_at: string;
  new_values: string;
  event_type_id: number;
  user_id: number;
  user: string;
  event: string;
}

export interface iOrderLogs {
  map(arg0: (log: any) => JSX.Element): import("react").ReactNode;
  length: number;
  slice: any;
  id: string;
  orderId: number;
  createdAt: string;
  newValues: string;
  eventTypeId: number;
  userId: number;
  user: string;
  event: string;
}

export interface iPaymentModel {
  status: string;
  payment_id: string;
  issuer: string;
  installments: number;
  qrCode: string;
  payment_method: string;
}

export interface iPayment {
  status: string;
  paymentId: string;
  issuer: string;
  installments: number;
  qrCode: string;
  paymentMethod: string;
}

export interface iOrderModel {
  id: number;
  orderToken: string;
  status: number;
  priority: boolean;
  with_problems: boolean;
  invoiced: boolean;
  archived_at?: string;

  customerId?: number;
  address_id: number;
  pickup_place_id?: number;

  selling_date: string;
  shipment_date?: string;
  // dueDate: string;

  transactionType: number;
  approvalId?: string;
  cost?: number;
  tax: number;
  total: number;
  due?: number;
  notes: string;
  shippingCost?: number;
  location_cost?: number;
  transporter_id?: number;
  delivery_date: string;
  delivery_time_start: string;
  delivery_time_end: string;

  products_json: string | iProductsList;
  payment_json?: string | iPayment;

  receptor_name: string;
  receptor_telephone: string;
  // address_id: number;
  pickup_place?: iPickupPlaceModel;
  customer?: iUserModel;
  address?: iAddressModel;
  rut?: iRutModel;
  transporter?: iTransporterModel;
  order_integrations?: iOrderIntegrationsModel[];
  logs?: Array<iOrderLogsModel>;
}

export default class Order extends EntityCRUD<iOrder> {
  static basePath = "/orders";

  static adapter(data: iOrder | iOrderModel) {
    if ((data as iOrderModel).selling_date !== undefined) {
      const model = data as iOrderModel;
      return {
        id: model.id.toString(),
        orderToken: model.orderToken,

        status: model.status.toString(),
        priority: model.priority,
        withProblems: model.with_problems,
        invoiced: model.invoiced,
        archived_at: model.archived_at,

        customerId: model.customerId?.toString(),
        addressId: model.address_id?.toString(),
        pickupPlaceId: model.pickup_place_id?.toString(),
        sellingDate: model.selling_date && model.selling_date,
        // ignore timezone
        shipmentDate:
          model.shipment_date &&
          new Date(model.shipment_date.replace(/Z$/, "")),
        ship: new Date(model.selling_date),
        // dueDate: model.dueDate && new Date(model.dueDate),
        notes: model.notes,
        receptorName: model.receptor_name,
        receptorTelephone: model.receptor_telephone,

        transporterId: model.transporter_id,
        deliveryTimeStart: model.delivery_time_start,
        deliveryTimeEnd: model.delivery_time_end,
        deliveryDate:
          model.delivery_date && model.delivery_date,
        transactionType: model.transactionType,
        approvalId: model.approvalId,

        cost: model.cost,
        tax: model.tax,
        total: model.total,
        due: model.due,
        shippingCost: model.shippingCost,
        locationCost: model.location_cost,

        products: Order.productsAdapter(
          model.products_json && typeof model.products_json === "string"
            ? JSON.parse(model.products_json)
            : model.products_json
        ),

        payment: Order.paymentAdapter(
          model.payment_json && typeof model.payment_json === "string"
            ? JSON.parse(model.payment_json)
            : model.payment_json
        ),

        customer: model.customer && User.adapter(model.customer as iUserModel),
        address:
          model.address && Order.addressAdapter(model.address as iAddressModel),
        rut:
          model.rut && Order.rutAdapter(model.rut as iRutModel),
        pickupPlace:
          model.pickup_place &&
          pickupPlace.adapter(model.pickup_place as iPickupPlaceModel),
        transporter:
          model.transporter && Transporter.adapter(model.transporter as iTransporterModel),
        orderIntegrations:
          model.order_integrations && OrderIntegrations.arrayAdapter(model.order_integrations as iOrderIntegrationsModel[]),
        orderLogs:
          model.logs &&
          model.logs.map((log) => Order.orderLogsAdapter(log as iOrderLogsModel)),
      } as iOrder;
    } else {
      const item = data as iOrder;
      return {
        id: +item.id,
        // orderToken: item.orderToken,

        status: +item.status,
        priority: item.priority,
        with_problems: item.withProblems,
        invoiced: item.invoiced ? item.invoiced : 0,
        archived_at: item.archived_at,

        customerId: item.customerId && +item.customerId,
        address_id: item.addressId && +item.addressId,
        pickup_place_id: item.pickupPlaceId && +item.pickupPlaceId,
        selling_date:
          item.sellingDate && item.sellingDate,
        shipment_date:
          item.shipmentDate && item.shipmentDate.toISOString
            ? item.shipmentDate.toISOString()
            : item.shipmentDate,
        // dueDate:
        //   item.dueDate && item.dueDate.toISOString
        //     ? item.dueDate.toISOString()
        //     : item.dueDate,
        notes: item.notes || undefined,
        receptor_name: item.receptorName,
        receptor_telephone: item.receptorTelephone,

        transporter_id: item.transporterId,
        delivery_date:
          item.deliveryDate && item.deliveryDate,
        delivery_time_start: item.deliveryTimeStart,
        delivery_time_end: item.deliveryTimeEnd,
        transactionType: item.transactionType,
        approvalId: item.approvalId,

        cost: item.cost,
        tax: item.tax,
        total: item.total,
        due: item.due,
        shippingCost: item.shippingCost,
        location_cost: item.locationCost,

        products_json: item.products,
        payment_json: item.payment,

        customer: item.customer && User.adapter(item.customer),
        address:
          // item.address && JSON.stringify(Order.addressAdapter(item.address)),
          item.address && Order.addressAdapter(item.address),
        rut: item.rut && Order.rutAdapter(item.rut),
        logs:
          item.orderLogs && Order.orderLogsAdapter(item.orderLogs),
      } as iOrderModel;
    }
  }

  static productsAdapter(data: iProductsList) {
    const regx = /\d+\.?\d*/;

    data.items = data.items.map((product) => {
      const match = product.orderPrice && regx.exec(product.orderPrice);
      return {
        ...product,
        orderPrice: match ? match[0] : undefined,
      };
    });
    return data;
  }

  static orderLogsAdapter(data: iOrderLogs | iOrderLogsModel | iOrderLogsModel[]) {
    if (Array.isArray(data)) {
      return data.map((log) => ({
        id: log.id,
        orderId: log.order_id,
        createdAt: log.created_at,
        newValues: log.new_values,
        eventTypeId: log.event_type_id,
        userId: log.user_id,
        event: log.event,
        user: log.user,
      })) as iOrderLogs[];
    } else if ((data as iOrderLogsModel).created_at !== undefined) {
      const model = data as iOrderLogsModel;
      return {
        id: model.id,
        orderId: model.order_id,
        createdAt: model.created_at,
        newValues: model.new_values,
        eventTypeId: model.event_type_id,
        userId: model.user_id,
        event: model.event,
        user: model.user,
      } as iOrderLogs;
    } else {
      const item = data as iOrderLogs;
      return {
        id: item.id,
        order_id: item.orderId,
        created_at: item.createdAt,
        new_values: item.newValues,
        event_type_id: item.eventTypeId,
        user_id: item.userId,
        event: item.event,
        user: item.user,
      } as iOrderLogsModel;
    }
  }

  static addressAdapter(data: iAddress | iAddressModel) {
    if ((data as iAddressModel).last_name !== undefined) {
      const model = data as iAddressModel;
      return {
        id: model.id,
        line1: model.line1,
        line2: model.line2,
        cityId: model.city_id,
        countryId: model.country_id,
        customerId: model.customer_id,
        // main: model.main,
        stateId: model.state_id,
        email: model.email,
        name: model.name,
        lastName: model.last_name,
        telephone: model.telephone,
        neighborhood: model.neighborhood,
        shippingCost: model.shipping_cost && Order.statesAdapter(model.shipping_cost),
      } as iAddress;
    } else {
      const item = data as iAddress;
      return {
        id: item.id,
        line1: item.line1,
        line2: item.line2,
        city_id: item.cityId,
        country_id: item.countryId,
        customer_id: item.customerId,
        // main: item.main,
        state_id: item.stateId,
        email: item.email,
        name: item.name,
        last_name: item.lastName,
        telephone: item.telephone,
        neighborhood: item.neighborhood,
        state: item.state && Order.statesAdapter(item.state),
      } as iAddressModel;
    }
  }

  static statesAdapter(data: iState | iStateModel) {
    if ((data as iStateModel).name !== undefined) {
      const model = data as iStateModel;
      return {
        id: model.id,
        placeName: model.name,
        cost: model.shipping_cost,
        enabled: model.enabled,
      } as iState;
    } else {
      const item = data as iState;
      return {
        id: item.id,
        name: item.placeName,
        shipping_cost: item.cost,
        enabled: item.enabled,
      } as iStateModel;
    }
  }

  static rutAdapter(data: iRut | iRutModel) {
    if ((data as iRutModel).rut !== undefined) {
      const model = data as iRutModel;
      return {
        id: model.id,
        invoiceAddress: model.address,
        orderId: model.order_id,
        invoiceRut: model.rut,
        invoiceName: model.business_name,
      } as iRut;
    } else {
      const item = data as iRut;
      return {
        id: item.id,
        address: item.invoiceAddress,
        order_id: item.orderId,
        rut: item.invoiceRut,
        business_name: item.invoiceName,
      } as iRutModel;
    }
  }

  static paymentAdapter(data: iPayment | iPaymentModel) {
    if (data === null || data === undefined) {
      return undefined;
    }

    if ((data as iPaymentModel) !== undefined) {
      const model = data as iPaymentModel;
      return {
        status: model.status,
        paymentId: model.payment_id,
        issuer: model.issuer,
        installments: model.installments,
        qrCode: model.qrCode,
        paymentMethod: model.payment_method
      } as iPayment;
    } else {
      const item = data as iPayment;
      return {
        status: item.status,
        payment_id: item.paymentId,
        issuer: item.issuer,
        installments: item.installments,
        qrCode: item.qrCode,
        payment_method: item.paymentMethod
      } as iPaymentModel;
    }
  }

  static create(data: iOrder) {
    return EntityCRUD.createBase<iOrder>(data);
  }

  // Export orders
  exportOrders(ids: Array<string>) {
    const path = `${Order.basePath}/export?ids=${ids.join(',')}`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, {}, { method: "get", responseType: "blob" });
          const downloadUrl = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'orders.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }

  // Export orders (with delivery info)
  exportDeliveryInfo(ids: Array<string>) {
    const path = `${Order.basePath}/export-delivery-info?ids=${ids.join(',')}`;

    return {
      key: path,
      fetcher: async () => {
        try {
          const response = await this.fetch(path, {}, { method: "get", responseType: "blob" });
          const downloadUrl = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', 'delivery_info.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
          return response;
        } catch (error) {
          console.log('Error handling', error);
          throw error;
        }
      },
    };
  }

  updateStatus(status: OrderStatus) {
    const path = `${Order.basePath}/${this.id}/status`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(path, { status }, { method: "put" }).then(
          (response) => {
            return {
              ...response,
              data: Order.adapter(response.data),
            };
          }
        );
      },
    };
  }

  archive() {
    const path = `${Order.basePath}/${this.id}/archive`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(path, undefined, { method: "put" }).then(
          (response) => {
            return {
              ...response,
              data: Order.adapter(response.data),
            };
          }
        );
      },
    };
  }

  updatePriority({ priority, withProblems }: iOrderPriorityUpdate) {
    const path = `${Order.basePath}/${this.id}/priority`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(
          path,
          { priority, with_problems: withProblems },
          { method: "put" }
        ).then((response) => {
          return {
            ...response,
            data: Order.adapter(response.data),
          };
        });
      },
    };
  }

  updateTransporter(transporterId: iOrder["transporterId"]) {
    const path = `${Order.basePath}/${this.id}/transporter`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(
          path,
          { transporter_id: transporterId },
          { method: "put" }
        ).then((response) => {
          return {
            ...response,
            data: Order.adapter(response.data),
          };
        });
      },
    };
  }

  updateInvoiced(invoiced: iOrder["invoiced"]) {
    const path = `${Order.basePath}/${this.id}/invoiced`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(
          path, 
          { invoiced }, 
          { method: "put" }
        ).then((response) => {
            return {
              ...response,
              data: Order.adapter(response.data),
            };
          }
        );
      },
    };
  }

  updateDueMoney(dueMoney: iOrder["due"]) {
    const path = `${Order.basePath}/${this.id}/due`;

    return {
      key: path,
      fetcher: () => {
        return this.fetch(
          path, 
          { due: dueMoney }, 
          { method: "put" }
          ).then((response) => {
            return {
              ...response,
              data: Order.adapter(response.data),
            };
          }
        );
      },
    };
  }
}